import React from 'react';

class Impressum extends React.Component {
   

  render() {
    return (
        <>
        <h1 class="entry-title page-title" > Impressum</h1>
        <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
<p><b>THE POINT OF SALE<br/></b>Firmensitz: Schleifmühlgasse 12-14 | 1040 Vienna<br/>Firmenname: THE POS KG<br/>Unternehmensgegenstand: Restaurant<br/>UID-Nr .: ATU 74398779<br/>Firmenbuchnummer: FN 513041 y <br/>Gewerbebehörde: Magistratisches Bezirksamt für den 04. und 05. Bezirk</p>
<p>Tel .: +43 (01) 9208547<br/>Email: office [@] thepointofsale.at</p>
<p>Mitglied der Wirtschaftskammer Wien</p>
<p><b>Kontaktdaten des Verantwortlichen für Datenschutz<br/></b>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br/>THE POS KG<br/>Schleifmühlgasse 12-14/L7 | 1040 Wien<br/>Vertretungsberechtigt: Th. Veluppillai</p>
<p>E-Mail-Adresse: office[@]thepointofsale.at<br/>Telefon: +43 (01) 9208547</p>
<p><b>Haftung für Inhalte dieser Website<br/></b>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
<p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon unberührt.</p>
<p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
<p><b>Haftung für Links auf dieser Website<br/></b>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
<p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
<p><b>Urheberrechtshinweis<br/></b>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
<p><b>Bildernachweis<br/></b>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
<p>Die Bilderrechte liegen beim Medieninhaber.</p>
<p>Alle Texte sind urheberrechtlich geschützt.</p>

<div style={{marginBottom:"180px"}}></div>
        </>
        );
  }
}
export default Impressum; 