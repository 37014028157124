import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

class Footer extends React.Component {


  render() {
    return (
      <div class="jumbotron text-center">
        <div>
          <p class="copy"> 
          <div class='row'>
            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>Schleifmühlgasse 12-14 | 1040 Wien &nbsp;&nbsp;&nbsp;&nbsp;</div>
            {/* <div className='col-sm-12 col-md-7 col-lg-4'> */}
            {/* <div class='row' style={{margin:'auto'}}> */}
              <span style={{"width": "120px"}}>Öffnungszeiten:</span>
              <span /* className='col-xs-12 col-sm-6 col-md-7 col-lg-7' */ style={{"text-align": "left","width": "200px"}}>&nbsp;Montag Ruhetag
              Dienstag bis Sonntag 10 bis 18 Uhr</span>
              {/* </div> */}
            {/* </div> */}
            <div className='col-sm-12 col-md-6 col-lg-4'>
            ©2025thepointofsale
            {/* <a href='/impressum' > Impressum</a> */}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to='/impressum' style={{ textDecoration: 'underline', color: 'green' }}>Impressum</Link>
            </div>
          </div>
        </p>

        </div>
      </div>
    )
  }
}
export default Footer;